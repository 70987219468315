import { type ControllerStageDataManifest } from '@wix/platform-editor-sdk';
import relatedPostsJson from '../components/RelatedPosts/.component.json';

export const overrideRelatedPostsWidgetManifest = (
  controllersStageData: ControllerStageDataManifest,
) =>
  (controllersStageData[relatedPostsJson.id] = {
    default: {
      gfpp: {
        desktop: {
          // @ts-expect-error
          iconButtons: { widgetPlugins: { behavior: 'HIDE' } },
        },
      },
    },
  });
