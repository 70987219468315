import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';

export const getWidgetManifest: GetWidgetManifestFn = (widgetBuilder, _, flowAPI) => {
  const { t } = flowAPI.translations;

  widgetBuilder.set({
    displayName: t('post-divider.displayName'),
    nickname: 'Post DividerWidget',
  });
};
