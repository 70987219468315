import {
  getBlogSettings,
  updateBlogSettings as updateBlogSettingsAmbassador,
} from '@wix/ambassador-blog-settings-v2-blog-settings/http';
import type { BlogSettings } from '@wix/ambassador-blog-settings-v2-blog-settings/types';
import type { EditorAppContext } from '../../types/editor-app-context.type';

export async function updateBlogSettings(
  context: EditorAppContext,
  blogSettings: Omit<BlogSettings, 'revision'>,
) {
  const response = await context.flowAPI.httpClient.request(getBlogSettings({}));
  const lastRevision = response.data.blogSettings?.revision;

  await context.flowAPI.httpClient.request(
    updateBlogSettingsAmbassador({
      blogSettings: { revision: lastRevision, ...blogSettings },
    }),
  );
}
