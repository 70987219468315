import type { ComponentRef } from '@wix/platform-editor-sdk';

export const MANAGE_POSTS_EVENT = 'manage-posts';
export const DELETE_BLOG_EVENT = 'delete-blog';
export const APP_ACTION_EVENT = 'appActionClicked';
export const COMPONENT_ADDED_TO_STAGE = 'componentAddedToStage';
export const EDITOR_ACTION_TYPE = 'editorActions';
export const APP_ACTION_EVENTS = {
  CREATE_POST: 'createPost',
  MANAGE_POSTS: 'managePosts',
  OPEN_BLOG_PAGES_PANEL: 'openPagesPanelOnBlog',
  OPEN_POST_PAGE_SETTINGS: 'openPostPageSettings',
  OPEN_BLOG_ADD_PANEL: 'openBlogAddPanel',
} as const;
export const INSTANCE_CHANGED = 'instanceChanged';

type Values<T> = T[keyof T];

type AppActionEvent = {
  eventType: typeof APP_ACTION_EVENT;
  eventPayload?: { actionId?: Values<typeof APP_ACTION_EVENTS> };
};

type ComponentAddedToStageEvent = {
  eventType: typeof COMPONENT_ADDED_TO_STAGE;
  eventPayload?: { compRef: ComponentRef };
};

type InstanceChangedEvent = {
  eventType: typeof INSTANCE_CHANGED;
  eventPayload?: { instance: string };
};

type ManagePostsEvent = {
  eventType: typeof MANAGE_POSTS_EVENT;
  eventPayload?: never;
};

type DeleteBlogEvent = {
  eventType: typeof DELETE_BLOG_EVENT;
  eventPayload?: never;
};

export type EditorEvent =
  | AppActionEvent
  | ComponentAddedToStageEvent
  | InstanceChangedEvent
  | ManagePostsEvent
  | DeleteBlogEvent;
