import type { EditorSDK } from '@wix/platform-editor-sdk';
import type { FlowAPI } from '@wix/yoshi-flow-editor';
import bottomOfPage2WidgetJson from '../../components/Bottom Of Page 2/.component.json';
import { blogCommentsWidgetId } from '../../external/common/constants/apps';
import { HEADER_COMPS_DATA, FOOTER_COMPS_DATA, CATEGORY_IDS } from './constants';

export enum PanelPlacementEnum {
  HEADER = 'header',
  FOOTER = 'footer',
}

type GetCountersElementsParams = {
  sdk: EditorSDK;
  flowAPI: FlowAPI;
  token: string;
  placement: PanelPlacementEnum;
};

const checkIfCommentsWidgetInstalled = async ({
  sdk,
  token,
}: Pick<GetCountersElementsParams, 'sdk' | 'token'>) => {
  try {
    const pluginsWithinSlot = await sdk.document.tpa.widgetPlugins.getWidgetSlots(token, {
      widgetId: bottomOfPage2WidgetJson.id,
    });

    const commentsWidget = pluginsWithinSlot.find(
      (plugin) => plugin.pluginInfo?.widgetId === blogCommentsWidgetId,
    );

    if (commentsWidget) {
      return true;
    }

    return false;
  } catch {
    return false;
  }
};

export const getCountersElements = async ({
  sdk,
  flowAPI,
  token,
  placement,
}: GetCountersElementsParams) => {
  const isCommentsWidgetInstalled = await checkIfCommentsWidgetInstalled({
    sdk,
    token,
  });

  const { t } = flowAPI.translations;

  const COMPS_DATA =
    placement === PanelPlacementEnum.HEADER ? HEADER_COMPS_DATA : FOOTER_COMPS_DATA;

  const counters = [
    {
      label: t('post.elements.likes'),
      identifier: { role: COMPS_DATA.likes.role },
      categoryId: CATEGORY_IDS.counters,
      index: 0,
    },
    {
      label: t('post.elements.views'),
      identifier: { role: COMPS_DATA.views.role },
      categoryId: CATEGORY_IDS.counters,
      index: 1,
    },
  ];

  if (isCommentsWidgetInstalled) {
    counters.push({
      label: t('post.elements.comments'),
      identifier: { role: COMPS_DATA.comments.role },
      categoryId: CATEGORY_IDS.counters,
      index: 2,
    });
  }

  return counters;
};
