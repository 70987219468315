import type { ContextParams } from '@wix/platform-editor-sdk';

export const getAssetsUrl = (options: ContextParams) => {
  if (process.env.NODE_ENV === 'development') {
    return 'https://localhost:33666/assets';
  }

  const url = options.initialAppData.editorScriptUrl ?? '';
  const urlWithoutScript = url.substring(0, url.lastIndexOf('/'));

  return `${urlWithoutScript}/assets`;
};
