export const CUSTOM_FEED_WIDGET_ID = '14e5b39b-6d47-99c3-3ee5-cee1c2574c89';
export const POST_LIST_WIDGET_ID = '813eb645-c6bd-4870-906d-694f30869fd9';
export const RECENT_POSTS_WIDGET_ID = '14e5b36b-e545-88a0-1475-2487df7e9206';
export const CUSTOM_FEED_LAYOUT_IDS = {
  minimal: '0',
  magazine: '3',
  sideBySide: '6',
  oneColumn: '7',
  postcard: '2',
};
export const POST_LIST_WIDGET_LAYOUT_IDS = {
  magazine: '13',
  sideBySide: '15',
  editorial: '16',
  oneColumn: '17',
  slider: '18',
};
export const CUSTOM_FEED_TOKEN = 'custom-feed-token';
