import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { selectors } from './elements';

export const getWidgetManifest: GetWidgetManifestFn = async (widgetBuilder) => {
  for (const selector of [
    selectors.likesDefaultNumber,
    selectors.likesFilledNumber,
    selectors.views,
    selectors.comments,
  ]) {
    widgetBuilder.configureConnectedComponents(selector, (builder) =>
      builder.behavior().set({ dataEditOptions: 'TEXT_STYLE_ONLY' }),
    );
  }
};
