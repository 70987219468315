import Raven from 'raven-js';
import type { ContextParams } from '@wix/platform-editor-sdk';

let fedopsInstance: ReturnType<ContextParams['essentials']['fedopsLoggerFactory']>;

type Options = {
  metaSiteId: string;
  instanceId: string;
  fedopsLoggerFactory: ContextParams['essentials']['fedopsLoggerFactory'];
};

const init = (options: Options) => {
  Raven.config('https://9e9da5f0dfd84ae1aae2739b09d3348e@sentry.wixpress.com/95', {
    tags: { metaSiteId: options.metaSiteId, instanceId: options.instanceId },
  }).install();

  fedopsInstance = options.fedopsLoggerFactory('communities-blog-editor-app');
};

const interactionStarted = (interactionName: string) => {
  try {
    return fedopsInstance.interactionStarted(interactionName);
  } catch (err) {
    Raven.captureException(err, {
      tags: { interactionName, fedopsError: 'interactionStarted' },
    });
  }
};

const interactionEnded = (interactionName: string) => {
  try {
    return fedopsInstance.interactionEnded(interactionName);
  } catch (err) {
    Raven.captureException(err, {
      tags: { interactionName, fedopsError: 'interactionEnded' },
    });
  }
};

const interactionFailed = (interactionName: string, err: any, message?: string) => {
  console.log(`${interactionName}${message ? ': ' + message : ''}`, err);
  Raven.captureException(err, { tags: { interactionName } });
};

const reportError = (error: unknown) => Raven.captureException(error);

const toMonitored = async (
  interactionName: string,
  promise: Promise<any>,
  rethrow: boolean = true,
  timeout?: number,
) => {
  let isResolved = false;

  try {
    interactionStarted(interactionName);

    if (timeout) {
      setTimeout(() => {
        interactionFailed(interactionName, 'Timeout exceeded');
        isResolved = true;
      }, timeout);
    }

    const response = await promise;

    if (!isResolved) {
      interactionEnded(interactionName);
    }

    return response;
  } catch (err) {
    interactionFailed(interactionName, err);
    if (rethrow) {
      throw err;
    }
  }
};

const log = (message: string, options = {}) => {
  Raven.captureMessage(message, { level: 'info', ...options });
};

export default {
  init,
  reportError,
  interactionStarted,
  interactionEnded,
  interactionFailed,
  toMonitored,
  log,
};
