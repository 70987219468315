import type { FlowAPI, GetAppManifestFn } from '@wix/yoshi-flow-editor';
import {
  APP_ACTION_EVENTS,
  DELETE_BLOG_EVENT,
  EDITOR_ACTION_TYPE,
  MANAGE_POSTS_EVENT,
} from '../constants/events';

/**
 * For local development of `communities-blog-pages-panel` package:
 * - Replace with https://localhost:37000
 */
const pagesPanelUrl = 'https://editor.wix.com/communities-blog-pages-panel';

const getAppManifest = (
  sdkScriptSrc: string,
  appToken: string,
  flowAPI: FlowAPI,
): ReturnType<GetAppManifestFn> => {
  const getPanelUrl = (slug: string) =>
    `${pagesPanelUrl}/${slug}?wix-editor-script=${sdkScriptSrc}&token=${appToken}`;

  const { t } = flowAPI.translations;

  return {
    pages: {
      pageActions: {
        default: [
          'Pages_Actions_Page_Rename' as any,
          {
            title: t('app-manifest.delete-page'),
            event: DELETE_BLOG_EVENT,
            icon: 'deleteRadio',
          },
        ],
        postPage: [],
      },
      pageSettings: {
        default: [
          {
            title: t('app-manifest.page-info'),
            type: 'page_info',
          },
          {
            title: t('app-manifest.layout'),
            type: 'layout',
          },
          {
            title: t('app-manifest.permissions'),
            type: 'permissions',
          },
          {
            title: t('app-manifest.seo'),
            type: 'seo',
          },
        ],
        postPage: [
          {
            title: t('app-manifest.page-info'),
            url: getPanelUrl('page-info'),
            type: 'page_info',
          },
          {
            title: t('app-manifest.layout'),
            type: 'layout',
          },
          {
            title: t('app-manifest.permissions'),
            type: 'permissions',
          },
          {
            title: t('app-manifest.seo'),
            url: getPanelUrl('seo'),
            type: 'seo',
          },
        ],
      },
      applicationSettings: {
        default: {
          displayName: t('app-manifest.blog-pages'),
        },
      },
      applicationActions: {
        default: [
          {
            title: t('app-manifest.manage-posts'),
            event: MANAGE_POSTS_EVENT,
            icon: 'settingsAction',
          },
          {
            title: t('app-manifest.delete-blog'),
            event: DELETE_BLOG_EVENT,
            icon: 'deleteRadio',
          },
        ],
      },
      pageDescriptors: {
        default: {
          icon: 'blogPageType',
        },
      },
    },
    appDescriptor: {
      mainActions: [
        {
          title: t('app-manifest.app-descriptor.create-post'),
          actionId: APP_ACTION_EVENTS.CREATE_POST,
          icon: 'appManager_addElementsAction',
        },
        {
          title: t('app-manifest.app-descriptor.manage-posts'),
          actionId: APP_ACTION_EVENTS.MANAGE_POSTS,
          icon: 'appManager_settingsAction',
        },
      ],
      customActions: [
        {
          title: t('app-manifest.app-descriptor.manage-blog-pages'),
          actionId: APP_ACTION_EVENTS.OPEN_BLOG_PAGES_PANEL,
          icon: 'appManager_pagesAction',
          type: EDITOR_ACTION_TYPE,
        },
        {
          title: t('app-manifest.app-descriptor.open-page-settings'),
          actionId: APP_ACTION_EVENTS.OPEN_POST_PAGE_SETTINGS,
          icon: 'customizeDesignButtonBrush',
          type: EDITOR_ACTION_TYPE,
        },
        {
          title: t('app-manifest.app-descriptor.add-blog-elements'),
          actionId: APP_ACTION_EVENTS.OPEN_BLOG_ADD_PANEL,
          icon: 'appManager_addElementsAction',
          type: EDITOR_ACTION_TYPE,
        },
        {
          title: t('app-manifest.app-descriptor.read-blogging-guide'),
          icon: 'sparkling-bulb-reg',
          type: EDITOR_ACTION_TYPE,
          url: 'https://www.wix-blog-community.com/guide',
        },
      ],
      defaultActions: {
        learnMoreKB: 'a7eeebda-8828-4a49-ba7e-d9b656caa197',
      },
    },
  };
};

export default getAppManifest;
