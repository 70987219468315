import type { EditorSDK } from '@wix/platform-editor-sdk';
import { blogAppDefId } from '../constants/apps';

export const getComponentRef = async (sdk: EditorSDK, widgetId: string) => {
  const blogAppData = await sdk.tpa.app.getDataByAppDefId('', blogAppDefId);
  if (!blogAppData) {
    return;
  }

  const blogAppComponents = await sdk.tpa.app.getAllCompsByApplicationId(
    '',
    blogAppData.applicationId,
  );
  if (!blogAppComponents) {
    return;
  }

  const component = blogAppComponents.find((c) => c.widgetId === widgetId);

  if (!component) {
    return;
  }

  const componentRef = await sdk.document.components.getById('', {
    id: component.id,
  });

  return componentRef;
};
