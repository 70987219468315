import { type WidgetBuilder, type AppManifestBuilder } from '@wix/yoshi-flow-editor';

import myPostsConfig from '../components/MyPosts/.component.json';
import { COMMENTS_WIDGET_ID, LIKES_WIDGET_ID } from '../external/common/constants/widgets';

const MEMBERS_WIDGETS_IDS = [myPostsConfig.id, LIKES_WIDGET_ID, COMMENTS_WIDGET_ID];

const configureMembersWidget = (widgetBuilder: WidgetBuilder) => {
  widgetBuilder.behavior().set({ removable: false });
  widgetBuilder.gfpp().set('widgetPlugins', { behavior: 'HIDE' });
};

export const configureMembersWidgets = (appManifestBuilder: AppManifestBuilder) => {
  MEMBERS_WIDGETS_IDS.forEach((widgetId) => {
    appManifestBuilder.configureWidget(widgetId, configureMembersWidget);
  });
};
