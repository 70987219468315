import { throttle } from 'lodash';
import { SETTINGS_EVENT_ID, UPDATE_FULL_POST_STYLE } from '@wix/communities-blog-client-common';
import type { EditorAppContext } from '../../../types/editor-app-context.type';
import concurrentEditing from './concurrent-editing';
import { eventListenersSplitPostPage } from './event-listeners-blocks-post-page';
import { setFullPostStyleParams } from './fullpost-layout-design-service';
import { savePostPageStyle } from './post-page-style';

export const addEventListeners = async (context: EditorAppContext) => {
  const { sdk } = context;
  const throttledSetParams = throttle(setFullPostStyleParams, 1000);

  await Promise.all([
    sdk.addEventListener('siteWasSaved', async () => {
      try {
        await savePostPageStyle(context);
      } catch (e) {}
    }),
    sdk.addEventListener('componentDataChanged', async (event) => {
      const eventType = (event?.detail?.previousData as any)?.content?.[SETTINGS_EVENT_ID]?.payload;

      if (eventType === UPDATE_FULL_POST_STYLE) {
        await concurrentEditing.withApproval(() => throttledSetParams(context));
      }
    }),
    eventListenersSplitPostPage(context),
  ]);
};
