import { get } from 'lodash';

export const getStyleProperty = (styles: any, property: string) =>
  get(styles, `style.properties.${property}`);

export const CATEGORY_LAYOUT_TYPE_STYLE_PARAM = 'layout-category-layoutType';
export const HOMEPAGE_LAYOUT_TYPE_STYLE_PARAM = 'layout-homepage-layoutType';

export const IS_POST_DESCRIPTION_ENABLED_PARAM = 'blog-isPostDescriptionEnabled';

export const HOMEPAGE_LAYOUT_TYPE_STYLE_PARAM_FULL =
  `param_number_${HOMEPAGE_LAYOUT_TYPE_STYLE_PARAM}` as const;
export const CATEGORY_LAYOUT_TYPE_STYLE_PARAM_FULL =
  `param_number_${CATEGORY_LAYOUT_TYPE_STYLE_PARAM}` as const;

export const AUTHOR_NAME_STYLE_ENABLED_PARAM_FULL =
  `param_boolean_blog-isAuthorNameEnabled` as const;
export const POST_PUBLISH_DATE_ENABLED_STYLE_PARAM_FULL =
  `param_boolean_blog-isPostPublishDateEnabled` as const;
export const IS_READING_TIME_ENABLED_PARAM_FULL =
  `param_boolean_blog-isReadingTimeEnabled` as const;
export const IS_VIEW_COUNT_ENABLED_PARAM_FULL = `param_boolean_blog-isViewCountEnabled` as const;
export const IS_LIKE_COUNT_ENABLED_STYLE_PARAM_FULL =
  `param_boolean_blog-isLikeCountEnabled` as const;
export const IS_COMMENT_COUNT_ENABLED_PARAM_FULL =
  `param_boolean_blog-isCommentCountEnabled` as const;
export const IS_POST_DESCRIPTION_ENABLED_PARAM_FULL =
  `param_boolean_${IS_POST_DESCRIPTION_ENABLED_PARAM}` as const;
