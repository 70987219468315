import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';

export const getWidgetManifest: GetWidgetManifestFn = (widgetBuilder, _, flowAPI) => {
  const { t } = flowAPI.translations;
  widgetBuilder.set({
    displayName: t('post-above-content-1.displayName'),
    nickname: 'Above content 1Widget',
  });

  widgetBuilder.behavior().set({
    removable: false,
  });

  widgetBuilder.gfpp().set('mainAction1', { behavior: 'HIDE' });
};
