import { type ElementsPanelParams, openElementsPanel } from '../../blocks/common/elements-panel';
import { type EditorAppContext } from '../../types/editor-app-context.type';
import { HEADER_COMPS_DATA, CATEGORY_IDS } from './constants';
import { getCountersElements, PanelPlacementEnum } from './getCountersElements';

export const openHeaderElementsPanel = async (
  context: EditorAppContext,
  widgetRef: ElementsPanelParams['widgetRef'],
) => {
  const countersElements = await getCountersElements({
    sdk: context.sdk,
    flowAPI: context.flowAPI,
    token: context.appToken,
    placement: PanelPlacementEnum.HEADER,
  });

  const { t } = context.flowAPI.translations;

  return openElementsPanel(context, {
    widgetRef,
    categoriesData: [
      {
        id: CATEGORY_IDS.mainElements,
        title: t('post.elements.main-elements'),
      },
      { id: CATEGORY_IDS.metadata, title: t('post.elements.metadata') },
      { id: CATEGORY_IDS.counters, title: t('post.elements.counters') },
      { id: CATEGORY_IDS.decorative, title: t('post.elements.decorative') },
    ],
    elementsData: [
      {
        label: t('post.elements.categories'),
        identifier: { role: HEADER_COMPS_DATA.categories.role },
        categoryId: CATEGORY_IDS.mainElements,
        index: 0,
      },
      {
        label: t('post.elements.title'),
        identifier: { role: HEADER_COMPS_DATA.title.role },
        categoryId: CATEGORY_IDS.mainElements,
        index: 1,
      },
      {
        label: t('post.elements.ratings'),
        identifier: { role: HEADER_COMPS_DATA.rating.role },
        categoryId: CATEGORY_IDS.mainElements,
        index: 2,
      },
      {
        label: t('post.elements.tags'),
        identifier: { role: HEADER_COMPS_DATA.tags.role },
        categoryId: CATEGORY_IDS.mainElements,
        index: 3,
      },
      {
        label: t('post.elements.writer'),
        identifier: { role: HEADER_COMPS_DATA.writer.role },
        categoryId: CATEGORY_IDS.mainElements,
        index: 4,
      },
      {
        label: t('post.elements.counters'),
        identifier: { role: HEADER_COMPS_DATA.counters.role },
        categoryId: CATEGORY_IDS.mainElements,
        index: 5,
      },
      // Metadata section
      {
        label: t('post.elements.writer'),
        identifier: { role: HEADER_COMPS_DATA.metadataWriter.role },
        categoryId: CATEGORY_IDS.metadata,
        index: 0,
      },
      {
        label: t('post.elements.publish-date'),
        identifier: { role: HEADER_COMPS_DATA.publishDate.role },
        categoryId: CATEGORY_IDS.metadata,
        index: 1,
      },
      {
        label: t('post.elements.reading-time'),
        identifier: { role: HEADER_COMPS_DATA.readingTime.role },
        categoryId: CATEGORY_IDS.metadata,
        index: 2,
      },
      // {
      //   label: t('post.elements.last-updated'),
      //   identifier: { role: HEADER_COMPS_DATA.lastUpdated.role },
      //   categoryId: CATEGORY_IDS.metadata,
      //   index: 3,
      // },
      // Counters section
      ...countersElements,
      // Decorative section
      {
        label: t('post.elements.divider'),
        identifier: { role: HEADER_COMPS_DATA.divider.role },
        categoryId: CATEGORY_IDS.decorative,
        index: 0,
      },
    ],
  });
};
