import type { TransactionError } from '@wix/platform-editor-sdk';
import { type EditorAppContext } from '../../../types/editor-app-context.type';
import monitoring from './monitoring';

const concurrentEditing = {
  init,
  withApproval: noop,
};

function init(context: EditorAppContext) {
  concurrentEditing.withApproval = createWithApproval(context);
}

const createWithApproval =
  ({ sdk, appToken }: EditorAppContext) =>
  async <T>(fn: () => T) => {
    try {
      return await sdk.document.transactions.runAndWaitForApproval(appToken, fn);
    } catch (error) {
      const isTransactionError = await sdk.document.transactions.isConflictError(
        appToken,
        error as TransactionError,
      );

      if (isTransactionError) {
        try {
          return await sdk.document.transactions.runAndWaitForApproval(appToken, fn);
        } catch (secondError) {
          monitoring.reportError(secondError);
        }
      } else {
        monitoring.reportError(error);
      }
    }
  };

async function noop<V>(fn: () => Promise<V>): Promise<Awaited<V>>;
async function noop<V>(fn: () => V): Promise<V> {
  return fn();
}

export default concurrentEditing;
