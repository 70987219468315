import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';

export const getWidgetManifest: GetWidgetManifestFn = (widgetBuilder, _, flowAPI) => {
  const { t } = flowAPI.translations;
  widgetBuilder.set({
    displayName: t('post-below-content-1.displayName'),
    nickname: 'Below content 2Widget',
  });

  widgetBuilder.behavior().set({
    removable: false,
  });

  widgetBuilder.gfpp().set('mainAction1', { behavior: 'HIDE' });
};
