import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';

export const getWidgetManifest: GetWidgetManifestFn = (widgetBuilder, _, flowAPI) => {
  const { t } = flowAPI.translations;
  widgetBuilder.set({
    displayName: t('post-tags.displayName'),
    nickname: 'Post TagsWidget',
  });

  widgetBuilder.gfpp().set('connect', { behavior: 'HIDE' });
};
