import type { ComponentRef } from '@wix/platform-editor-sdk';
import type { EditorAppContext } from '../types/editor-app-context.type';

interface AddWidgetPluginParams {
  widgetId: string;
  slotCompRef: ComponentRef;
  context: EditorAppContext;
  appDefinitionId: string;
}

export const addWidgetPlugin = ({
  widgetId,
  slotCompRef,
  context,
  appDefinitionId,
}: AddWidgetPluginParams) =>
  context.sdk.tpa.widgetPlugins.addWidgetPlugin(context.appToken, {
    slotCompRef,
    widgetPluginPointer: {
      appDefinitionId,
      widgetId,
    },
  });
