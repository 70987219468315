import tinycolor from '@ctrl/tinycolor';
import {
  DeviceType,
  type ColorParam,
  type ColorStyleParam,
  type FontStyleParam,
  type SiteColor,
  type StyleParam,
} from '@wix/platform-editor-sdk';
import type { ISettingsColor, IStyleParam } from '@wix/tpa-settings';
import {
  getLayoutType,
  isLayoutFullPost,
  raise,
  SECTION_HOMEPAGE,
  type LayoutType,
} from '@wix/communities-blog-client-common';
import styleParamsV2 from '@app/components/Post/stylesParams';

import { type EditorAppContext } from '@app/types/editor-app-context.type';
import { BLOG_WIDGET_ID, POST_WIDGET_ID } from '../constants/widgets';
import { isRtlLanguage } from './is-rtl-language';
import { getComponentRef } from './sdk-utils';

/**
 * - editor.app.ts Listens for `componentDataChanged` event with UPDATE_FULL_POST_STYLE as payload
 * - Feed: Sends UPDATE_FULL_POST_STYLE when "Layout: Full Post" is selected
 * - Post: Sends UPDATE_FULL_POST_STYLE when "Design: Text style/Background" settings are changed
 */
export const setFullPostStyleParams = async (context: EditorAppContext) => {
  const { sdk, appToken } = context;
  const compRef = (await getComponentRef(sdk, BLOG_WIDGET_ID)) ?? raise('Feed widget not found');
  const feedStyleParams = await sdk.document.tpa.getStyleParams(appToken, { compRef });
  const layoutType = feedStyleParams.numbers[getLayoutType(SECTION_HOMEPAGE)] as LayoutType;

  if (!isLayoutFullPost(layoutType)) {
    return;
  }

  const styleParams = await getMappedPostPageStyleParams(context);

  await sdk.document.tpa.setStyleParams(appToken, { compRef, styleParams });
};

export const syncedFullPostLayoutStyleParams = {
  fonts: [
    styleParamsV2.textParagraphFont,
    styleParamsV2.textQuoteFont,
    styleParamsV2.textH1Font,
    styleParamsV2.textH2Font,
    styleParamsV2.textH3Font,
    styleParamsV2.textH4Font,
    styleParamsV2.textH5Font,
    styleParamsV2.textH6Font,
    styleParamsV2.ratingsTextFont,
  ],
  colors: [
    styleParamsV2.textParagraphColor,
    styleParamsV2.textQuoteColor,
    styleParamsV2.textH1Color,
    styleParamsV2.textH2Color,
    styleParamsV2.textH3Color,
    styleParamsV2.textH4Color,
    styleParamsV2.textH5Color,
    styleParamsV2.textH6Color,
    styleParamsV2.textLinksAndHashtagsColor,
    styleParamsV2.postBorderColor,
    styleParamsV2.postBackgroundColor,
    styleParamsV2.ratingsStarEmptyColor,
    styleParamsV2.ratingsStarFilledColor,
    styleParamsV2.ratingsTextColor,
  ],
  booleans: [],
  numbers: [styleParamsV2.postBorderWidth],
} as const;

const getMappedPostPageStyleParams = async (context: EditorAppContext): Promise<StyleParam[]> => {
  const { sdk, appToken } = context;

  /** Post */
  const compRef = (await getComponentRef(sdk, POST_WIDGET_ID)) ?? raise('Post widget not found');

  const postPageStyleParams = await sdk.tpa.getStyleParams(appToken, { compRef });
  const [siteColors, textPresets, dimensions, editorMode, currentLanguage] = await Promise.all([
    sdk.tpa.getSiteColors(appToken, { compRef }),
    sdk.tpa.getSiteTextPresets(appToken, { compRef }),
    sdk.document.environment.screen.getScreenResolution(),
    sdk.info.getEditorMode(),
    sdk.language.current.get(appToken),
  ]);

  function extractDefaultValue(param: IStyleParam) {
    const isEditorX = context.flowAPI.environment.isEditorX;
    const isMobile = editorMode === DeviceType.Mobile;
    const isRTL = isRtlLanguage(currentLanguage);
    const experiments = context.flowAPI.experiments;

    const defaultValue = param.getDefaultValue?.({
      colors: siteColors,
      dimensions,
      getStyleParamValue: (legacyParam, options) => {
        return legacyParam.getDefaultValue?.({
          colors: siteColors,
          dimensions,
          experiments,
          isEditorX,
          getStyleParamValue: () => undefined,
          isMobile,
          isRTL,
          textPresets,
        });
      },
      experiments,
      isEditorX,
      isMobile,
      isRTL,
      textPresets,
    });

    return defaultValue;
  }

  const fonts = syncedFullPostLayoutStyleParams.fonts.map<StyleParam>((param) => ({
    type: 'font',
    key: param.key!,
    param: {
      value: postPageStyleParams.fonts[param.key!] ?? extractDefaultValue(param),
    } as FontStyleParam['param'],
  }));
  const numbers = syncedFullPostLayoutStyleParams.numbers.map<StyleParam>((param) => ({
    type: 'number',
    key: param.key!,
    param: { value: postPageStyleParams.numbers[param.key!] ?? extractDefaultValue(param) },
  }));
  const colors = syncedFullPostLayoutStyleParams.colors.map<StyleParam>((param) => ({
    type: 'color',
    key: param.key!,
    param: transformColor(
      postPageStyleParams.colors[param.key!] ?? extractDefaultValue(param),
      siteColors,
    ),
  }));

  const params = [...fonts, ...numbers, ...colors];

  return params;
};

const transformColor = (
  color: ColorParam | ISettingsColor,
  siteColors: SiteColor[],
): ColorStyleParam['param'] => {
  // This is copied from communities-blog-settings, need a different function because a different tinycolor is used in settings
  // TODO: Unify tinycolor version in settings

  if ('themeName' in color) {
    if (!color.themeName) {
      return {
        value: {
          rgba: color.value,
          opacity: tinycolor(color.value).isValid ? tinycolor(color.value).getAlpha() : 1,
        },
      } as ColorStyleParam['param'];
    }

    const siteColor = siteColors.find(
      ({ reference }) => reference === color.value || reference === color.themeName,
    );
    const themeName = siteColor?.name ?? color.themeName;
    const colorValue = siteColor?.value ?? color.value;

    return {
      value: {
        rgba: colorValue,
        opacity: tinycolor(color.value).isValid ? tinycolor(color.value).getAlpha() : 1,
        ...(themeName && { color: { name: themeName } }),
      },
    } as ColorStyleParam['param'];
  }

  if (!color.name) {
    return {
      value: {
        rgba: color.value,
        opacity: tinycolor(color.value).isValid ? tinycolor(color.value).getAlpha() : 1,
      },
    } as ColorStyleParam['param'];
  }

  const siteColor = siteColors.find(
    ({ reference }) => reference === color.value || reference === color.name,
  );
  const themeName = siteColor?.name ?? color.name;
  const colorValue = siteColor?.value ?? color.value;

  return {
    value: {
      rgba: colorValue,
      opacity: tinycolor(color.value).isValid ? tinycolor(color.value).getAlpha() : 1,
      ...(themeName && { color: { name: themeName } }),
    },
  } as ColorStyleParam['param'];
};
