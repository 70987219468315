import classicPreset from '../assets/blocks-post-page/classic-preset.png';
import overlayPreset from '../assets/blocks-post-page/overlay-preset.png';
import stackedPreset from '../assets/blocks-post-page/stacked-preset.png';

const POST_PAGE_PRESET_RTL = {
  DESKTOP_CLASSIC: 'variants-lnisw8eu',
  DESKTOP_OVERLAY: 'variants-lniw3ojg',
  DESKTOP_STACKED: 'variants-lniwbvh8',
  MOBILE_CLASSIC: 'variants-lnitwirp',
  MOBILE_OVERLAY: 'variants-lniw3ojg',
  MOBILE_STACKED: 'variants-lniwhc6x',
} as const;

const POST_PAGE_PRESET_LTR = {
  DESKTOP_CLASSIC: 'variants-ln2sz6bn1',
  DESKTOP_OVERLAY: 'variants-lniv6gp0',
  DESKTOP_STACKED: 'variants-lniw545p',
  MOBILE_CLASSIC: 'variants-lnirkhtr',
  MOBILE_OVERLAY: 'variants-lnivui2q',
  MOBILE_STACKED: 'variants-lniwdp8d',
} as const;

export const getWidgetPresets = (rtl: boolean) => {
  const presetConfig = rtl ? POST_PAGE_PRESET_RTL : POST_PAGE_PRESET_LTR;

  return [
    {
      id: presetConfig.DESKTOP_CLASSIC,
      connectedMobilePresetID: presetConfig.MOBILE_CLASSIC,
      src: classicPreset,
    },
    {
      id: presetConfig.DESKTOP_OVERLAY,
      connectedMobilePresetID: presetConfig.MOBILE_OVERLAY,
      src: overlayPreset,
    },
    {
      id: presetConfig.DESKTOP_STACKED,
      connectedMobilePresetID: presetConfig.MOBILE_STACKED,
      src: stackedPreset,
    },
  ];
};
