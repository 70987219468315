import type { EditorAppContext } from '../../../types/editor-app-context.type';
import { blogAppDefId } from '../constants/apps';
import { TPA_PAGE_ID_BLOG, TPA_PAGE_ID_POST } from '../constants/tpa-pages';

export const addPagesToMenu = async ({ sdk, appToken, flowAPI }: EditorAppContext) => {
  const [{ applicationId }, pages] = await Promise.all([
    sdk.tpa.app.getDataByAppDefId(appToken, blogAppDefId),
    sdk.pages.data.getAll(appToken),
  ]);

  const { t } = flowAPI.translations;

  let postPageRef;

  if (pages) {
    pages
      .filter(
        (page) =>
          page.tpaApplicationId === applicationId &&
          [TPA_PAGE_ID_BLOG, TPA_PAGE_ID_POST].includes(page.tpaPageId!),
      )
      .forEach(async (page) => {
        const data = { managingAppDefId: blogAppDefId };

        if (page.tpaPageId === 'post') {
          postPageRef = { id: page.id, type: page.type };
          // @ts-expect-error
          data.title = t('app-manifest.post-page-label');
        }

        await sdk.pages.data.update(appToken, {
          // @ts-expect-error
          pageRef: { id: page.id!, type: page.type },
          data,
        });
      });
  }

  if (postPageRef) {
    await sdk.document.pages.setState(appToken, {
      state: {
        postPage: [postPageRef],
      },
    });
  }
};
