import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';

export const getWidgetManifest: GetWidgetManifestFn = (widgetBuilder) => {
  const ui = {
    author: 'text1',
    published: 'text5',
    timeToRead: 'text2',
    updated: 'text3',
  };

  widgetBuilder.configureConnectedComponents(ui.author, (builder) =>
    builder.behavior().set({ dataEditOptions: 'TEXT_STYLE_ONLY' }),
  );

  widgetBuilder.configureConnectedComponents(ui.published, (builder) =>
    builder.behavior().set({ dataEditOptions: 'TEXT_STYLE_ONLY' }),
  );

  widgetBuilder.configureConnectedComponents(ui.timeToRead, (builder) =>
    builder.behavior().set({ dataEditOptions: 'TEXT_STYLE_ONLY' }),
  );

  widgetBuilder.configureConnectedComponents(ui.updated, (builder) =>
    builder.behavior().set({ dataEditOptions: 'TEXT_STYLE_ONLY' }),
  );
};
