import type { EditorSDK } from '@wix/platform-editor-sdk';

const base64DecodeUnicode = (base64String: string) =>
  decodeURIComponent(
    atob(base64String)
      .split('')
      .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
      .join(''),
  );

export const parseInstanceWithoutValidation = (
  instance: string,
): { metaSiteId: string; siteMemberId?: string } | undefined => {
  const [, data] = instance.split('.');
  return JSON.parse(base64DecodeUnicode(data));
};

export const getSiteMemberId = async (sdk: EditorSDK, appToken: string) => {
  try {
    const encodedInstance = await sdk.document.info.getAppInstance(appToken);
    const instance = parseInstanceWithoutValidation(encodedInstance);
    return instance?.siteMemberId ?? '';
  } catch (_) {
    return '';
  }
};

export const getMetaSiteId = (instance: string) => {
  try {
    const instanceObject = parseInstanceWithoutValidation(instance);
    return instanceObject?.metaSiteId;
  } catch (_) {
    return;
  }
};
